import {gql} from '@apollo/client'

export default gql`
  query ShopQuery($collection: String!, $country: CountryCode) @inContext(country: $country){
    collection(handle: $collection) {
      title
      handle
      image {
        url
      }

      products(first: 250) {
        edges {
          node {
            availableForSale
            handle
            id
            title
            tags

            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }

            images(first: 2) {
              edges {
                node {
                  id
                  altText
                  url
                }
              }
            }

            variants(first: 6) {
              edges {
                node {
                  availableForSale
                  compareAtPrice {
                    amount
                  }
                  id
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`
