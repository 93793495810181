import {gql} from '@apollo/client'

export default gql`
  query FetchCollection($country: CountryCode, $collectionId: ID!) @inContext(country: $country){
    collection (id: $collectionId) {
      ... on Collection {
        handle
        id
        title

        products(first: 30) {
          edges {
            node {
              availableForSale
              handle
              id
              title
              tags

              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }

              images(first: 2) {
                edges {
                  node {
                    id
                    altText
                    url
                  }
                }
              }

              variants(first: 6) {
                edges {
                  node {
                    availableForSale
                    compareAtPrice {
                      amount
                    }
                    id
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
