// @flow
import React from 'react'
import isMobile from 'is-mobile'

import type {ShopifyCollectionType} from '../../common/types'
import {shopTheme, uiLocations} from '../../common/constants'
import {getPrices} from '../../common/shop'
import {analytics} from '../../common/analytics'

import Grid, {GridItem} from '../Grid'
import Link from '../Link'
import ShopLoading from '../ShopLoading'
import NotFound from '../NotFound'
import Page from '../Page'
import ShopNav from '../ShopNav'
import shopNavCollectionSlugs from '../ShopNav/shopNavCollectionSlugs'

import styles from './Shop.less'

type ShopProps = {
  collection: ShopifyCollectionType,
  web_settings: {
    shop_offline: boolean,
  },
  loading: boolean,
  slug: string,
  shopLocale: string,
}

export default class ShopComponent extends React.Component {
  props: ShopProps

  componentDidMount() {
    this.handleTrackingGA()
  }

  componentDidUpdate = () => this.handleTrackingGA()

  handleTrackingGA = () => {
    analytics.trackConversion('AW-855500528/gR3nCK-jyO8CEPDN95cD')

    const collection = this.props.collection
    if (collection && collection.products && collection.products && collection.products.edges) {
      analytics.track('view_item_list', {
        item_list_name: 'shop_page',
        items: collection.products.edges.map(item => ({
          item_id: item.node.id,
          item_name: item.node.title,
          price: item.node.priceRange.minVariantPrice.amount,
        })),
      }, ['ga'])
    }
  }

  render() {
    const {loading, slug, web_settings} = this.props
    const collection = this.props.collection

    if (loading || !collection) return <ShopLoading activeSlug={slug} />

    const products = collection && collection.products && collection.products.edges
    if (!products) return <NotFound />

    const collectionTitle = collection.title === 'All' ? 'Shop' : collection.title
    const collectionImage = collection.image && collection.image.url
    const isPasswordProtected = collection.title === 'Shadow Store'
    const activeSlug = slug || 'all'

    return (
      <Page
        title={collectionTitle}
        iconColour='#000'
        image={collectionImage}
        invertColours={true}
        theme={shopTheme}
        isPasswordProtected={isPasswordProtected}
        subNavigation={<ShopNav activeSlug={activeSlug} />}
        enableSubscribeOverlay={true}
        ssr={true}
        optInGateTags={['shop']}
      >
        {web_settings.shop_offline ?
          <Offline /> :
          <Products products={products} activeSlug={activeSlug} />
        }
      </Page>
    )
  }
}

const Products = ({products, activeSlug}) => {
  return (
    <div>
      <Grid className={styles.Products} gutter={false}>
        {products.map((product, index) => (
          <GridItem
            key={index}
            responsiveWidths={{
              small: 6,
              medium: 4,
              large: 3,
              extraLarge: 3,
            }}
          >
            <ShopProductCard
              key={product.node.id}
              product={product.node}
              index={index}
              activeSlug={activeSlug}
            />
          </GridItem>
        ))}
      </Grid>
    </div>
  )
}

export const ShopProductCard = (props: any) => {
  if (!props.product) return null
  const {availableForSale, handle, images, title, tags, variants} = props.product

  const {price, compareAtPrice, discountPercent} = getPrices(props.product)

  const mainImage = images.edges[0] && images.edges[0].node.url
  const secondaryImage = images.edges[1] && images.edges[1].node.url
  const canHoverSwitchImage = !isMobile() && secondaryImage
  const isLocked = tags.includes('Locked')
  const isOnSale = compareAtPrice && discountPercent
  const isGetRestockNotification = tags.includes('EnableRestockNotification')

  const availableVariants = []

  // Checking if variant is in stock and has a non-default title
  if (variants) {
    for (const variant of variants.edges) {
      if (variant.node.availableForSale && variant.node.title !== 'Default Title') availableVariants.push(variant)
    }
  }

  // Hide product if it has the Hidden tag
  if (tags.includes('Hidden')) return null

  // Hide a product from the collections shown in the navigation
  // if it has the HideFromNavigationCollections tag
  if (shopNavCollectionSlugs.includes(props.activeSlug) && tags.includes('HideFromNavigationCollections')) return null

  return (
    <Link
      className={canHoverSwitchImage ? styles.HoverCard : styles.Card}
      internalLink={`/product/${handle}`}
      uiLocation={uiLocations.shopProductCard}
    >
      <ProductImage image={mainImage} availableForSale={availableForSale} isLocked={isLocked} title={title} />

      {canHoverSwitchImage &&
        <ProductImage image={secondaryImage} availableForSale={availableForSale} isLocked={isLocked} title={title} />
      }

      {!availableForSale &&
        <div className={isGetRestockNotification ? styles.GetRestockBanner : styles.SoldOutBanner}>
          SOLD OUT {isGetRestockNotification && <div>GET RESTOCK REMINDER</div>}
        </div>
      }

      <div className={styles.Info}>
        {title}
        <div className={styles.Price}>
          <Price
            isLocked={isLocked}
            availableForSale={availableForSale}
            isOnSale={isOnSale}
            price={price}
            compareAtPrice={compareAtPrice}
            discountPercent={discountPercent}
          />
        </div>

        {!isLocked && availableForSale && !!availableVariants.length &&
          <div className={styles.Variants}>
            {availableVariants.map(variant => (
              <span className={styles.Variant} key={variant.node.title}>
                {variant.node.title}
              </span>
            ))}
          </div>
        }
      </div>
    </Link>
  )
}

const Price = props => {
  const {isLocked, availableForSale, isOnSale, price, compareAtPrice, discountPercent} = props

  if (isLocked) return 'Coming Soon'
  if (!availableForSale) return 'Sold Out'
  if (!isOnSale) return price

  return (
    <div className={styles.PriceWrapper}>
      <span className={styles.OldPrice}>{compareAtPrice}</span>

      <span className={styles.CurrentPrice}>{price}</span>

      <span className={styles.DiscountPercent}>{discountPercent} off</span>
    </div>
  )
}

const ProductImage = ({image, availableForSale, isLocked, title}) => {
  const altText = `Apparel product photo of ${title}`
  return (
    <div
      className={styles.Image}
      style={{
        opacity: availableForSale || isLocked ? 1 : 0.4,
        backgroundImage: image ? `url(${image})` : '',
      }}
      title={altText}
      aria-label={altText}
    />
  )
}

const Offline = () =>
  <div className={styles.Offline}>
    NEW SEASON COMING SOON
  </div>
