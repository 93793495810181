// @flow
import React from 'react'
import {connect} from 'react-redux'
import {useQuery} from '@apollo/client'

import type {State} from '../../stateType'

import GET_PRODUCTS from './Query'
import SHOP_SETTINGS from './SettingsQuery'

import Query from '../Query'
import ShopComponent from './Component'

const mapStateToProps = ({shop}: State) => ({
  shopLocale: shop.shopLocale,
})

const Shop = props => {
  const slug = props.match.params.slug || 'all'
  const {data} = useQuery(SHOP_SETTINGS)
  const web_settings = data && data.web_settings

  return (
    <Query
      Component={ShopComponent}
      query={GET_PRODUCTS}
      variables={{collection: slug, country: props.shopLocale}}
      props={{slug, web_settings}}
      key={slug}
      context={{clientName: 'shopify'}}
    />
  )
}

export default connect(mapStateToProps)(Shop)
