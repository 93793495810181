// @flow
import React from 'react'
import {useSelector} from 'react-redux'
import LazyLoad from 'react-lazyload'

import {determineDefaultHomepage} from './../../../lib/defaultHomepage'

import type {State} from '../../stateType'
import type {PageSize, HomepageType, BrandType, MatchType, StaticContext} from '../../common/types'
import paths from '../../common/paths'
import {brandIds} from '../../common/brands'
import {gutterSize, playlistDisplayOptions, uiLocations} from '../../common/constants'

import Page from '../Page/'
import Carousel from '../Carousel/'
import Loading from '../Loading/'
import NotFound from '../NotFound/'
import Slider from '../Slider/'
import HeroPanel from '../HeroPanel/'
import HomepageHeader from '../HomepageHeader/'
import HomepageFooter from '../HomepageFooter/'
import ShopSlider from '../ShopSlider'
import LowHeat from '../LowHeat'
import UpcomingSlider from '../UpcomingSlider'
import Button from '../Button'
import Query from '../Query'
import CitiesGrid from '../CitiesGrid'
import Signup from '../Signup'
import Tour from '../../microsites/Tour'
import Tile from '../Tile'
import Grid, {GridItem} from '../Grid'

import BoilerRoomXMap from './BoilerRoomXMap'

import GET_HOME_PAGE from './Query'

import styles from './Homepage.less'

type Props = {
  location: {
    pathname: string,
    search: string,
  },

  staticContext: StaticContext,
  match: MatchType,

  isLoggedIn: boolean,
  startRedirect: () => void,
}

const getHomepageId = (props: Props, brand: BrandType) => {
  // Local development /hompage/123
  if (props.match.params.id) return props.match.params.id
  // Boiler Room video page /video
  if (brand.id === brandIds.boilerroom && props.match.path === '/video') return 32
  // Boiler Room Audio page /audio
  if (brand.id === brandIds.boilerroom && props.match.path === '/audio') return 35
  // 4:3 video page /video
  if (brand.id === brandIds.fourthree && props.match.path === '/video') return 33
  // Homepage from SSR staticContext
  if (props.staticContext) return props.staticContext.defaultHomepage

  return determineDefaultHomepage()
}

export default (props: Props) => {
  const {pageSize, brand} = useSelector(({page, brand}: State) => ({
    pageSize: page.pageSize,
    brand,
  }))

  const homepageId = getHomepageId(props, brand)
  // HOTFIX
  // if world tour then allow more items per slider
  // possible that upping this value across the board casued massive DB load
  const sliderLimit = homepageId === 36 ? 30 : 12

  return (
    <Query
      Component={Home}
      query={GET_HOME_PAGE}
      fetchPolicy='cache-first'
      variables={{id: homepageId, limit: sliderLimit}}
      props={{
        pageSize,
        brand,
        path: props.match.path,
      }}
    />
  )
}

class Home extends React.Component {
  props: {
    homepage: HomepageType,
    loading: boolean,
    pageSize: PageSize,
    brand: BrandType,
    defaultHomepage: ?number,
    path: string,
  }

  render() {
    const {homepage, loading, pageSize, path} = this.props

    if (!loading && !homepage) return <NotFound />
    if (loading && !homepage) return <Loading />

    const heroPanelEnabled = homepage.theme && homepage.theme.enable_hero_panel_header

    const forceAudio = path === '/audio'

    const {sponsor, theme, display_title, description, requires_login, thumbnail_image, signup_enabled} = homepage

    const isBoilerRoomHome = determineDefaultHomepage() === determineDefaultHomepage('boilerroom.tv') && path === '/'
    const isLowHeat = this.props.brand.id === brandIds.lowheat
    const isWorldTour = determineDefaultHomepage() === 36
    const isHardDanceTour = determineDefaultHomepage() === 26
    const isBoilerRoomX = determineDefaultHomepage() === 40
    const isSystem = determineDefaultHomepage() === 21

    const collections = isBoilerRoomHome ? homepage.collections.slice(1) : homepage.collections
    const lazyloadCollections = collections.slice(3)
    const instantLoadCollections = collections.slice(0, 3)

    const hasCTA = homepage.theme && homepage.theme.cta_text && homepage.theme.cta_link

    if (isLowHeat) return <LowHeat homepage={homepage} />

    if (isHardDanceTour) return <Tour homepage={homepage} pageTitle={'Boiler Room Hard Dance Tour'} pageDescription={'Boiler Room Hard Dance'} />

    if (isWorldTour) return <Tour homepage={homepage} pageTitle={'Boiler Room World Tour'} pageDescription={'Boiler Room’s biggest artists, showcased across the globe. The Boiler Room World Tour invites you to see some of the most essential artists we’ve championed over the years alongside intimate broadcasts of vital local scenes in eighteen key cities—raw, uncut and in the round.'} />

    const Header = theme && <HomepageHeader logo={homepage.logo_image} theme={theme} />

    const Footer = theme && <HomepageFooter theme={theme} sponsor={sponsor} />

    return (
      <Page
        header={heroPanelEnabled ? <div /> : Header}
        footer={Footer}
        theme={theme}
        title={display_title}
        description={description}
        requiresLogin={requires_login}
        brandId={homepage.brand.id}
        image={thumbnail_image}
        contentColumn={this.props.brand.id === brandIds.fourthree}
        ssr={true}
        disableFooterSignup={signup_enabled}
      >
        <div style={theme && theme.body_text_color ? {color: theme.body_text_color} : {}}>
          {heroPanelEnabled && <HeroPanel homepage={homepage} />}
          {isBoilerRoomHome &&
            <HomepageCollection
              collection={homepage.collections[0]}
              homepage={homepage}
              pageSize={pageSize}
              forceAudio={forceAudio}
              enableDescription={isSystem}
            />
          }

          {isBoilerRoomX && <BoilerRoomXMap />}

          <div className={homepage.center_sliders ? styles.SlidersCentral : styles.Sliders}>
            {isBoilerRoomHome && <BoilerRoomHomeContent />}

            {instantLoadCollections.map(collection => (
              <HomepageCollection
                key={collection.id}
                collection={collection}
                homepage={homepage}
                pageSize={pageSize}
                forceAudio={forceAudio}
                enableSliderDescription={isBoilerRoomX}
                enableDescription={isSystem}
              />
            ))}

            {lazyloadCollections.length !== 0 &&
              <LazyLoad once offset={800}>
                {lazyloadCollections.map(collection => (
                  <HomepageCollection
                    key={collection.id}
                    collection={collection}
                    homepage={homepage}
                    pageSize={pageSize}
                    forceAudio={forceAudio}
                    enableDescription={isSystem}
                  />
                ))}
              </LazyLoad>
            }

            {homepage.signup_enabled && homepage.signup &&
              <div className={styles.SignupWrapper}>
                <Signup signup={homepage.signup} />
              </div>
            }

            {!isBoilerRoomHome && !homepage.hide_shop_slider && <ShopSlider />}

          </div>

          {hasCTA && homepage.theme && heroPanelEnabled &&
            <Button
              text={homepage.theme.cta_text}
              href={homepage.theme.cta_link}
              target={'_blank'}
              style={{
                position: 'fixed',
                top: `${gutterSize}px`,
                right: `${gutterSize}px`,
                color: homepage.theme.cta_color,
                backgroundColor: homepage.theme.cta_background_color,
              }}
            />
          }
        </div>
      </Page>
    )
  }
}

// This content is only visible on the Boiler Room
// homepage, it appears in between the carousel and
// the first slider
const BoilerRoomHomeContent = () => (
  <div>
    <UpcomingSlider />

    <ShopSlider />

    <CitiesGrid />
  </div>
)

const CollectionSlider = ({
  collection,
  removeHeader,
  removeEmptySpace,
  forceAudio,
  isVideoOnly,
  enableDescription,
}) => (
  <Slider
    title={collection.title}
    description={collection.summary || collection.description}
    key={collection.id}
    internalLink={paths.collection(collection)}
    items={collection.items}
    itemsCount={collection.items_count}
    logoImage={collection.logo_image}
    removeHeader={removeHeader}
    removeEmptySpace={removeEmptySpace}
    forceAudio={forceAudio}
    isVideoOnly={isVideoOnly}
    theme={collection.theme}
    homepageDisplayMode={collection.homepage_display_mode}
    enableDescription={enableDescription}
  />
)

const StackedTiles = ({items, forceAudio}) => (
  <Grid>
    {items.map(item =>
      <GridItem key={item.id} responsiveWidths={{small: 12, medium: 6, large: 3, extraLarge: 3}}>
        <Tile item={item} className={styles.StackedTile} uiLocation={uiLocations.stackedTile} forceAudio={forceAudio} />
      </GridItem>
    )}
  </Grid>
)

const StackedPanels = ({items, forceAudio}) =>
  items.map(item =>
    <Tile
      key={item.id}
      item={item}
      className={styles.StackedPanel}
      uiLocation={uiLocations.stackedPanel}
      forceAudio={forceAudio}
    />
  )

const HomepageCollection = ({collection, homepage, pageSize, forceAudio, enableDescription}) => {
  if (!collection) return null

  switch (collection.homepage_display_mode) {
    case playlistDisplayOptions.carousel:
      return (
        <Carousel
          collection={collection}
          backgroundColor={homepage.theme && homepage.theme.background_color}
          displaySponsorLogos={!homepage.theme}
          pageSize={pageSize}
        />
      )
    case playlistDisplayOptions.stackedPanels:
      return <StackedPanels items={collection.items} forceAudio={forceAudio} />
    case playlistDisplayOptions.stackedTiles:
      return <StackedTiles items={collection.items} forceAudio={forceAudio} />
    default:
      return (
        <CollectionSlider
          collection={collection}
          removeHeader={homepage.hide_slider_headers}
          removeEmptySpace={homepage.remove_empty_space}
          forceAudio={forceAudio}
          isVideoOnly={homepage.id === 32}
          enableDescription={enableDescription}
        />
      )
  }
}
