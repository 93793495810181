import React from 'react'
import ReactDOM from 'react-dom'
import {createBrowserHistory} from 'history'
import {Router} from 'react-router-dom'
import {ApolloProvider} from '@apollo/client'
import {Provider} from 'react-redux'
import {applyMiddleware, compose, createStore} from 'redux'
import ReduxThunk from 'redux-thunk'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'raf/polyfill'
import Cookies from 'js-cookie'
import {init} from '@sentry/browser'
import {v4} from 'uuid'

import Routes from './components/Routes'
import makeReducer from './state'
import {actions as pageActions} from './components/Page/state'
import {actions as scrollActions} from './components/Scrolling/state'
import {webClient} from './common/apolloClient'
import {actions as authActions} from './components/Auth/state'
import {actions as shopActions} from './components/Shop/state'

import {hasWindow} from './common/constants'
import {analytics} from './common/analytics'

if (process.env.SENTRY_ENV) {
  init({
    dsn: 'https://84993a6d11ba432f99bd5fd45cfb21fb@sentry.io/92450',
    environment: process.env.SENTRY_ENV,
  })
}

const client = webClient()

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__
const brand = window.__BRAND__
const brands = window.__BRANDS__
const geoLocation = window.__GEOLOCATION__

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__
delete window.__APOLLO_STATE__
delete window.__BRAND__
delete window.__BRANDS__

const store = createStore(
  makeReducer({
    brand: () => brand,
    brands: () => brands,
    loadLocation: () => window.location,
    geoLocation: () => geoLocation,
  }),
  preloadedState,
  compose(applyMiddleware(ReduxThunk)),
)

const updateDimensions = () => store.dispatch(pageActions.updateDimensions())
const updateScrollTop = () => store.dispatch(scrollActions.updateScrollTop())

var history = null

if (hasWindow) {
  updateDimensions()
  window.addEventListener('resize', updateDimensions)
  window.addEventListener('orientationchange', updateDimensions)

  updateScrollTop()
  window.addEventListener('scroll', updateScrollTop)

  history = createBrowserHistory()
  history.listen(location => {
    if (window.gtag) {
      window.gtag('config', 'UA-25535944-2', {page_path: location.pathname})
      window.gtag('config', 'AW-855500528', {page_path: location.pathname})
      window.gtag('config', 'G-72RBK2YEH0', {page_path: location.pathname})
    }

    analytics.track('PageView', {}, ['fb'])
  })

  const checkoutId = Cookies.get('checkoutId')
  if (checkoutId) store.dispatch(shopActions.setCheckoutId(checkoutId))

  store.dispatch(authActions.onLaunch())
  store.dispatch(shopActions.onLaunch())

  // Analytics cookie setting
  const sessionId = Cookies.get('session_id')
  const existingOrNewSessionId = sessionId || v4() // if a session_id exists, use the existing value or create a new id
  Cookies.set('session_id', existingOrNewSessionId, {domain: 'boilerroom.tv', expires: 1 / 48}) // set the session_id cookie with a 30 minute expiry

  const urlParams = new URLSearchParams(window.location.search) // get search params to store in cookies for user analytics

  const utmCampaign = urlParams.get('utm_campaign')
  const utmMedium = urlParams.get('utm_medium')
  const utmSource = urlParams.get('utm_source')

  if (utmCampaign) Cookies.set('utm_campaign', utmCampaign, {domain: 'boilerroom.tv', expires: 2})
  if (utmMedium) Cookies.set('utm_medium', utmMedium, {domain: 'boilerroom.tv', expires: 2})
  if (utmSource) Cookies.set('utm_source', utmSource, {domain: 'boilerroom.tv', expires: 2})

  if (document.referrer) Cookies.set('referrer', document.referrer, {domain: 'boilerroom.tv'}) // set referrer session cookie for user analytics
}

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <Router history={history}>
        <Routes />
      </Router>
    </ApolloProvider>
  </Provider>,
  document.getElementById('app'),
)
